import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import noop from 'lodash/noop'
import propTypes from 'prop-types'
import ReactPlayer from 'react-player'
import {StyledVideoEmbed} from './videoEmbed.styles'
import {useIntersection} from '../../../hooks/useIntersection'

function Video({
  thumbnail,
  videoLink,
  loopOver = false,
  controls = false,
  onPlay = noop,
  onPause = noop,
  onError = noop,
  playIcon,
  playing = false,
}) {
  const playTriggered = useRef(false)
  const {nodeRef, isIntersecting} = useIntersection()
  const lightOption = useMemo(() => {
    return thumbnail ? thumbnail : true
  }, [thumbnail])

  const handlePlayClick = useCallback(() => {
    playTriggered.current = true
    onPlay()
  }, [onPlay])

  useEffect(() => {
    if (playing && !isIntersecting) {
      onPause()
    }
  }, [onPause, playing, isIntersecting])

  const handleOverlayClick = useCallback(() => {
    if (playing) {
      onPause()
    } else {
      handlePlayClick()
    }
  }, [playing, onPause, handlePlayClick])

  return (
    <StyledVideoEmbed className="video-embed" ref={nodeRef}>
      {playTriggered.current && (
        <div
          className="overlay-video"
          onClick={handleOverlayClick}
          data-ispaused={!playing}
        />
      )}
      <ReactPlayer
        className="player-wrapper"
        url={videoLink}
        loop={loopOver}
        controls={controls}
        light={lightOption}
        width="100%"
        height="100%"
        playsinline={true}
        playIcon={playIcon}
        playing={playing}
        onPlay={onPlay}
        onPause={onPause}
        onError={onError}
        onClickPreview={handlePlayClick}
      />
    </StyledVideoEmbed>
  )
}

export default Video

const _propTypes = {
  thumbnail: propTypes.string,
  videoLink: propTypes.string.isRequired,
  loopOver: propTypes.bool,
  controls: propTypes.bool,
  onPlay: propTypes.func,
  onPause: propTypes.func,
  onError: propTypes.func,
  playIcon: propTypes.node,
}

Video.propTypes = _propTypes
