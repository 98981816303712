import styled from 'styled-components'

export const StyledArrowHead = styled.div`
  border: ${({isSelected, color}) =>
    !isSelected
      ? color
        ? `solid ${color}`
        : 'solid var(--brand-primary-text-color)'
      : 'solid var(--brand-primary-white-color)'};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;

  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: 4px;
  }

  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-top: -4px;
  }
`

export const ArrowHead = ({direction, isSelected = false, color = ''}) => {
  return (
    <StyledArrowHead
      className={`${direction}`}
      isSelected={isSelected}
      color={color}
    />
  )
}
